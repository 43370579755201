import React, { useState, useRef, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors } from '../../theme';
import { isWeb } from '../../lib/is-web';

type Props = {
  title: string;
  children: React.ReactNode;
  style?: object;
  enterDelay?: number;
  leaveDelay?: number;
  backgroundColor?: string;
  zIndex?: number;
  position?: 'top' | 'bottom' | 'left' | 'right';
};

export const CustomTooltip = ({ 
  title, 
  children, 
  enterDelay = 0,
  leaveDelay = 0,
  style = {},
  backgroundColor,
  zIndex = 1000,
  position = 'bottom'
}: Props) => {
  const [visible, setVisible] = useState(false);
  const childrenRef = useRef<View>(null);
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Clean up timeouts
  useEffect(() => {
    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
    };
  }, []);



  const showTooltip = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }

    if (enterDelay > 0) {
      showTimeoutRef.current = setTimeout(() => {
        setVisible(true);
      }, enterDelay);
    } else {
      setVisible(true);
    }
  };

  const hideTooltip = () => {
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current);
      showTimeoutRef.current = null;
    }

    if (leaveDelay > 0) {
      hideTimeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, leaveDelay);
    } else {
      setVisible(false);
    }
  };

  if (!isWeb) return children;

  const webSpecificEvents = {
    onMouseEnter: showTooltip,
    onMouseLeave: hideTooltip,
  };

  // Get position styles 
  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return {
          bottom: '100%',
          left: '50%',
          marginBottom: 5,
          transform: [{ translateX: -50 }]
        };
      case 'left':
        return {
          right: '100%',
          top: '50%',
          marginRight: 5,
          transform: [{ translateY: -50 }]
        };
      case 'right':
        return {
          left: '100%',
          top: '50%',
          marginLeft: 5,
          transform: [{ translateY: -50 }]
        };
      case 'bottom':
      default:
        return {
          top: 29,
          left: '50%',
          transform: [{ translateX: -50 }]
        };
    }
  };

  return (
    <View style={[styles.container, style]}>
      <View
        ref={childrenRef}
        style={styles.tooltipTrigger}
        // @ts-ignore - Web-specific events
        {...webSpecificEvents}
      >
        {children}
      </View>
      {visible && (
        <View 
          style={[
            styles.webTooltip,
            getPositionStyles() as any,
            {
              backgroundColor: backgroundColor || colors.text,
              zIndex,
            }
          ]}
        >
          <Text style={styles.tooltipText}>{title}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    ...(isWeb ? { display: 'inline-block' } : {}) as any
  },
  tooltipTrigger: {
    position: 'relative',
  },
  webTooltip: {
    position: 'absolute',
    ...(isWeb ? { width: 'max-content' } : {}) as any,
    backgroundColor: colors.text,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    zIndex: 1000,
  },
  tooltipText: {
    color: '#fff',
    fontSize: 15,
    textAlign: 'center',
  },
});