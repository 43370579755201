import { useRecoilState } from 'recoil';
import {
  allChildNotesState,
  allClassNotesState,
  assessmentsState,
} from './store';
import { useApi } from './hooks/use-api';
import { Components } from './server';
import { useAuth } from './hooks/use-auth';
import { useNetInfo } from '@react-native-community/netinfo';
import { alertCross, useCurrentUser } from './utils/utils';
import { showToast } from './lib/toast';

export const useChildNoteOps = () => {
  const { authAxios } = useApi();
  const [allNotes, setData] = useRecoilState(allChildNotesState);
  const { demo } = useAuth();
  const user = useCurrentUser();
  const { isConnected } = useNetInfo();

  if (!demo && !isConnected) {
    return {
      async addChildNote(_child: number, _created_at: string, _note: string) {
        alertCross(
          'Jste offline',
          'Pro přidání poznámky se nejprve připojte k internetu',
        );
      },
      async editChildNote(
        _noteId: number,
        _child: number,
        _created_at: string,
        _note: string,
      ) {
        alertCross(
          'Jste offline',
          'Pro úpravu poznámky se nejprve připojte k internetu',
        );
      },
      async deleteChildNote(_noteId: number, _child: number) {
        alertCross(
          'Jste offline',
          'Pro odebrání poznámky se nejprve připojte k internetu',
        );
      },
    };
  }

  return {
    async addChildNote(child: number, created_at: string, note: string) {
      let data: Components.Schemas.ChildNote;

      if (!demo) {
        const res = await authAxios.post<Components.Schemas.ChildNote>(
          '/child-notes/',
          { child, note, created_at },
        );
        data = res.data;
      } else {
        data = {
          id:
            Object.values(allNotes).reduce((n, x) => Math.max(n, x.id), 0) + 1,
          child,
          note,
          created_at,
          created_by: user?.id,
        };
      }
      setData((xs) => ({ ...xs, [data.id]: data }));
    },

    async editChildNote(
      noteId: number,
      child: number,
      created_at: string,
      note: string,
    ) {
      let data: Components.Schemas.ChildNote;

      if (!demo) {
        const res = await authAxios.put(`/child-notes/${noteId}/`, {
          child,
          note,
          created_at,
        });
        data = res.data;
      } else {
        data = { ...allNotes[noteId]!, child, note, created_at };
      }
      setData((xs) => ({ ...xs, [noteId]: data }));
    },

    async deleteChildNote(noteId: number, _child: number) {
      if (!demo) {
        await authAxios.delete(`/child-notes/${noteId}/`);
      }
      setData(({ [noteId]: _, ...xs }) => xs);
    },
  };
};

export const useClassroomNoteOps = () => {
  const { authAxios } = useApi();
  const [allNotes, setData] = useRecoilState(allClassNotesState);
  const user = useCurrentUser();
  const { demo } = useAuth();
  const { isConnected } = useNetInfo();

  if (!demo && !isConnected) {
    return {
      async addClassroomNote(
        _classroom: number,
        _created_at: string,
        _note: string,
      ) {
        alertCross(
          'Jste offline',
          'Pro přidání poznámky se nejprve připojte k internetu',
        );
      },
      async editClassroomNote(
        _noteId: number,
        _classroom: number,
        _created_at: string,
        _note: string,
      ) {
        alertCross(
          'Jste offline',
          'Pro úpravu poznámky se nejprve připojte k internetu',
        );
      },
      async deleteClassroomNote(_noteId: number, _classroom: number) {
        alertCross(
          'Jste offline',
          'Pro odebrání poznámky se nejprve připojte k internetu',
        );
      },
    };
  }

  return {
    async addClassroomNote(
      classroom: number,
      created_at: string,
      note: string,
    ) {
      let data: Components.Schemas.ClassroomNote;

      if (!demo) {
        const res = await authAxios.post<Components.Schemas.ClassroomNote>(
          '/class-notes/',
          { created_at, classroom, note },
        );
        data = res.data;
      } else {
        data = {
          id:
            Object.values(allNotes).reduce((n, x) => Math.max(n, x.id), 0) + 1,
          classroom,
          note,
          created_at,
          created_by: user?.id,
        };
      }
      setData((xs) => ({ ...xs, [data.id]: data }));
    },

    async editClassroomNote(
      noteId: number,
      classroom: number,
      created_at: string,
      note: string,
    ) {
      let data: Components.Schemas.ClassroomNote;

      if (!demo) {
        const res = await authAxios.put(`/class-notes/${noteId}/`, {
          created_at,
          classroom,
          note,
        });
        data = res.data;
      } else {
        data = { ...allNotes[noteId]!, created_at, classroom, note };
      }
      setData((xs) => ({ ...xs, [noteId]: data }));
    },

    async deleteClassroomNote(noteId: number, _classroom: number) {
      if (!demo) {
        await authAxios.delete(`/class-notes/${noteId}/`);
      }
      setData(({ [noteId]: _, ...xs }) => xs);
    },
  };
};

export const useAssessmentOps = () => {
  const { authAxios } = useApi();
  const [_, setData] = useRecoilState(assessmentsState);
  const user = useCurrentUser();
  const { demo } = useAuth();
  const { isConnected } = useNetInfo();

  if (!demo && !isConnected) {
    return {
      async addAssessments(
        _assessment: Omit<Components.Schemas.Assessment, 'id'>[],
      ) {
        alertCross(
          'Jste offline',
          'Pro přidání hodnocení se nejprve připojte k internetu',
        );
      },
      async editAssessment(
        _assessmentId: number,
        _assessment: Omit<Components.Schemas.Assessment, 'id'>,
      ) {
        alertCross(
          'Jste offline',
          'Pro úpravu hodnocení se nejprve připojte k internetu',
        );
      },
      async deleteAssessment(
        _assessmentId: number,
        _assessment: Omit<Components.Schemas.Assessment, 'id'>,
      ) {
        alertCross(
          'Jste offline',
          'Pro odebrání hodnocení se nejprve připojte k internetu',
        );
      },
    };
  }

  return {
    async addAssessments(
      assessments: Omit<Components.Schemas.Assessment, 'id'>[],
    ) {
      if (!demo) {
        try {
          const { data } = await authAxios.post(
            '/assessments/create_multiple/',
            assessments,
          );
          setData((xs) => {
            const newData = { ...xs };
            data.forEach((item: any) => {
              newData[item.id] = item;
            });
            return newData;
          });
          showToast(
            'Hodnocení bylo uloženo',
            'Upravit si ho můžete v sekci "poslední hodnocení"',
            'success',
            3000,
          );
        } catch (error: any) {
          if (error.response?.status === 403) {
            showToast(
              'Nemáte oprávnění',
              error.response.data.detail ||
                'Nemáte oprávnění vytvářet hodnocení pro dítě',
              'error',
              6000,
            );
          } else {
            showToast(
              'Něco se pokazilo',
              'Při vytváření hodnocení došlo k chybě',
              'error',
              6000,
            );
            setData((xs) => {
              const newData = { ...xs };
              let id =
                Object.values(xs).reduce((n, x) => Math.max(n, x.id), 0) + 1;
              assessments.forEach((item) => {
                newData[id] = { ...item, id, assessed_by: user?.id };
                id += 1;
              });
              return newData;
            });
          }
        }
      }
    },

    async editAssessment(
      assessmentId: number,
      assessment: Omit<Components.Schemas.Assessment, 'id'>,
    ) {
      if (!demo) {
        try {
          const res = await authAxios.put(
            `/assessments/${assessmentId}/`,
            assessment,
          );
          setData((xs) => ({ ...xs, [assessmentId]: res.data }));
          showToast('Úspěšně', 'Hodnocení bylo upraveno', 'success', 3000);
        } catch (error: any) {
          if (error.response?.status === 403) {
            showToast(
              'Oprávnění zamítnuto',
              error.response.data.detail ||
                'Nemáte oprávnění upravovat toto hodnocení',
              'error',
              6000,
            );
          } else {
            showToast(
              'Něco se pokazilo',
              'Při úpravě hodnocení došlo k chybě',
              'error',
              6000,
            );
          }
        }
      } else {
        setData((xs) => ({
          ...xs,
          [assessmentId]: { ...xs[assessmentId]!, ...assessment },
        }));
      }
    },

    async deleteAssessment(
      assessmentId: number,
      _assessment: Omit<Components.Schemas.Assessment, 'id'>,
    ) {
      if (!demo) {
        await authAxios.delete(`/assessments/${assessmentId}/`);
      }
      setData(({ [assessmentId]: _, ...xs }) => xs);
    },
  };
};
