interface TourStep {
  id: string;
  target: string;
  title: string;
  content: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  mobilePosition?: 'top' | 'bottom' | 'left' | 'right';
  spotlight?: boolean;
  hideOnMobile?: boolean;
  action?: () => void;
}

interface TourSteps {
  [key: string]: TourStep[];
}


export const TOUR_STEPS: TourSteps = {
  CLASS_HOME_SCREEN: [
    {
      id: 'header_menu',
      target: 'header_menu',
      title: 'Menu aplikace',
      content: 'Zde najdete hlavní menu aplikace s přístupem k různým funkcím.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'small_logo',
      target: 'small_logo',
      title: 'Logo - Domovská stránka',
      content: 'Kliknutím zde se vrátíte na domovskou stránku.',
      position: 'right',
      spotlight: true
    },
    {
      id: 'class_selector',
      target: 'class_header',
      title: 'Výběr třídy',
      content: 'Kliknutím zde můžete přepínat mezi třídami.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'start_assessment',
      target: 'start_assessment_button',
      title: 'Začít hodnocení',
      content: 'Klikněte zde pro zahájení výběru dítěte/dětí a nasledného hodnocení.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'last_assessment',
      target: 'last_assessment_button',
      title: 'Poslední hodnocení',
      content: 'Kliknutím se zobrazí přehled posledních hodnocení dětí.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'children_card',
      target: 'children_card',
      title: 'Přehled dětí',
      content: 'Seznam všech dětí ve třídě a možnost vybrání a nasledného ohodnocení.',
      position: 'right',
      spotlight: true
    },
    {
      id: 'kufr_card',
      target: 'kufr_card',
      title: 'Klokanův kufr',
      content: 'Zde se dostanete a seznámíte se s diagnostikou Klokanova kufru.',
      position: 'left',
      spotlight: true
    },
    {
      id: 'stats_card',
      target: 'stats_card',
      title: 'Statistiky třídy',
      content: 'Celkový graf přehledu hodnocení ve třídě a podrobné vyzobrazení přehledu dětí.',
      position: 'right',
      spotlight: true
    },
    {
      id: 'notes_card',
      target: 'notes_card',
      title: 'Poznámky',
      content: 'Správa poznámek k vaší třídě a dětí.',
      position: 'left',
      spotlight: true
    }
  ],
  CLASS_HOME_SCREEN_MOBILE: [
    {
      id: 'header_menu',
      target: 'header_menu',
      title: 'Menu aplikace',
      content: 'Zde najdete hlavní menu aplikace s přístupem k různým funkcím.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'class_selector',
      target: 'class_header',
      title: 'Výběr třídy',
      content: 'Kliknutím zde můžete přepínat mezi třídami.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'start_assessment',
      target: 'start_assessment_button',
      title: 'Začít hodnocení',
      content: 'Klikněte zde pro zahájení výběru dítěte/dětí a nasledného hodnocení.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'last_assessment',
      target: 'last_assessment_button',
      title: 'Poslední hodnocení',
      content: 'Kliknutím se zobrazí přehled posledních hodnocení dětí.',
      position: 'bottom',
      spotlight: true
    },
    {
      id: 'children_card',
      target: 'children_card',
      title: 'Přehled dětí',
      content: 'Seznam všech dětí ve třídě a možnost vybrání a nasledného ohodnocení.',
      position: 'right',
      spotlight: true
    },
    {
      id: 'kufr_card',
      target: 'kufr_card',
      title: 'Klokanův kufr',
      content: 'Zde se dostanete a seznámíte se s diagnostikou Klokanova kufru.',
      position: 'left',
      spotlight: true
    },
    {
      id: 'stats_card',
      target: 'stats_card',
      title: 'Statistiky třídy',
      content: 'Celkový graf přehledu hodnocení ve třídě a podrobné vyzobrazení přehledu dětí.',
      position: 'right',
      spotlight: true
    },
    {
      id: 'notes_card',
      target: 'notes_card',
      title: 'Poznámky',
      content: 'Správa poznámek k vaší třídě a dětí.',
      position: 'left',
      spotlight: true
    }
  ],
    CLASS_CHILDREN_SCREEN: [
      {
        id: 'class_header_collapse',
        target: 'class_header_collapse',
        title: 'Ovládání záhlaví',
        content: 'Kliknutím zde můžete skrýt nebo zobrazit hledací pole a třídu.',
        position: 'bottom',
        spotlight: true
      },
      {
        id: 'search_bar',
        target: 'search_bar',
        title: 'Vyhledávání',
        content: 'Rychle vyhledejte dítě podle jména nebo příjmení.',
        position: 'bottom',
        spotlight: true
      },
      {
        id: 'child_list',
        target: 'child_list',
        title: 'Seznam dětí',
        content: 'Přehled všech dětí ve třídě. Můžete vybrat více dětí naráz a vyplnit úkoly přes tlačítko v pravém spodním rohu.',
        position: 'top',
        spotlight: true
      },
      {
        id: 'child_list_item',
        target: 'child_list_item',
        title: 'Položka dítěte',
        content: 'Kliknutím zobrazíte detail dítěte, kliknutím na checkbox nebo dlouhým podržením aktivujete režim výběru a přes tlačítko v pravém spodním rohu se dostanete na vyplnění hodnocení.',
        position: 'top',
        spotlight: true
      },
      {
        id:"multi_fab_button",
        target:"multi_fab_button",
        title:"Rychlé akce",
        content: 'Kliknutím zde otevřete nabídku pro hodnocení nebo poznámek. Při výběru dětí se tlačítko zbarví zeleně.',
        position: 'left',
        spotlight: true
      }
    ],
    CLASS_CATEGORY_SCREEN: [
      {
        id: 'category_list',
        target: 'category_list',
        title: 'Seznam oblastí',
        content: 'Zde se nachází seznam oblastí pro diagnostiku Klokanova kufru.',
        position: 'top',
        spotlight: true
      },
      {
        id: 'category_card',
        target: 'category_card',
        title: 'Oblast diagnostiky',
        content: 'Kliknutím na kartu oblasti se dostanete k jejím úkolům a aktivitám.',
        position: 'bottom',
        spotlight: true
      },
      {
        id: 'multi_fab_button',
        target: 'multi_fab_button',
        title: 'Rychlé akce',
        content: 'Kliknutím zde otevřete nabídku pro hodnocení nebo poznámek. Při výběru dětí se tlačítko zbarví zeleně.',
        position: 'left',
        spotlight: true
      }
    ],
    CLASS_STATS_SCREEN: [
      {
        id: 'roll_options',
        target: 'roll_options',
        title: 'Filtrování dat',
        content: 'Kliknutím rozbalíte možnosti pro filtrování zobrazených dat.',
        position: 'bottom',
        spotlight: true
      },
      {
        id: 'stats_overview_card',
        target: 'stats_overview_card',
        title: 'Přehled třídy',
        content: 'Zde vidíte celkový přehled třídy včetně průměrného věku a počtu dětí.',
        position: 'top',
        spotlight: true
      },
      {
        id: 'download_pdf',
        target: 'download_pdf',
        title: 'Stáhnout PDF',
        content: 'Stáhněte si celkový graf dané třídy.',
        position: 'bottom',
        spotlight: true
      },
      {
        id:'category_icons_chart',
        target:'category_icons_chart',
        title:'Graf oblastí',
        content:'Kliknutím na ikonu se dostanete k dané oblasti.',
        position:'top',
        spotlight:true
      },
      {
        id:'date_slider',
        target:'date_slider',
        title:'Historie hodnocení',
        content:'Posuvkou si můžete zobrazit historii hodnocení a graf se upraví na vybrané období. Nebo můžete kliknout přímo na tlačítko s datumem.',
        position:'top',
        spotlight:true
      },
      {
        id: 'multi_fab_button',
        target: 'multi_fab_button',
        title: 'Rychlé akce',
        content: 'Kliknutím zde otevřete nabídku pro hodnocení nebo poznámek. Při výběru dětí se tlačítko zbarví zeleně.',
        position: 'left',
        spotlight: true
      }
    ],
    CLASS_NOTES_SCREEN: [
      {
        id: 'select_type_selector',
        target: 'select_type_selector',
        title: 'Vyhledávání a přepínání',
        content: 'Vyhledávání a přepínání mezi poznámkami třídy a dětí.',
        position: 'top',
        spotlight: true
      },
      {
        id: 'class_notes_list',
        target: 'class_notes_list',
        title: 'Seznam poznámek třídy',
        content: 'Zde najdete všechny poznámky k této třídě. Kliknutím na poznámku ji můžete upravit.',
        position: 'top',
        spotlight: true
      },
      {
        id:'note_card',
        target:'note_card',
        title:'Detail poznámky',
        content:'Kliknutím na poznámku se dostanete k editoru poznámky.',
        position:'top',
        spotlight:true
      },
      {
        id: 'add_note_button',
        target: 'multi_fab_button',
        title: 'Přidat poznámku',
        content: 'Kliknutím zde přidáte novou poznámku ke třídě nebo k vybraným dětem.',
        position: 'left',
        spotlight: true
      }
    ],
    EDIT_CLASSROOM_NOTE: [
      {
        id: 'note_delete',
        target: 'note_delete',
        title: 'Smazání poznámky',
        content: 'Kliknutím zde smažete poznámku třídy.',
        position: 'top',
        spotlight: true
      },
      {
        id:"date_picker",
        target:"date_picker",
        title:"Datum vytvoření poznámky",
        content:'Kliknutím na datum se dostanete k vyberte datum.',
        position:'top',
        spotlight:true
      },
      {
        id: 'note_input',
        target: 'note_input',
        title: 'Poznámka',
        content: 'Zde můžete editovat text poznámky.',
        position: 'top',
        spotlight: true
      }
    ], 
    CHILD_OVERVIEW_SCREEN: [
      {
        id: 'stats_overview_card',
        target: 'stats_overview_card',
        title: 'Přehled dítěte',
        content: 'Zde vidíte celkový přehled dítěte včetně vyplněnosti oblastí.',
        position: 'left',
        spotlight: true
      },
      {
        id: 'download_pdf',
        target: 'download_pdf',
        title: 'Stáhnout PDF',
        content: 'Stáhněte si celkový graf dané třídy.',
        position: 'bottom',
        spotlight: true
      },
      {
        id:'category_icons_chart',
        target:'category_icons_chart',
        title:'Graf oblastí',
        content:'Kliknutím na ikonu se dostanete k dané oblasti.',
        position:'top',
        spotlight:true
      },
      {
        id: 'date_slider',
        target: 'date_slider',
        title: 'Historie hodnocení',
        content: 'Posunutím můžete zobrazit statistiky z různých období.',
        position: 'top',
        spotlight: true
      },
      {
        id: 'multi_fab_button',
        target: 'multi_fab_button',
        title: 'Rychlé akce',
        content: 'Přidání nového hodnocení nebo poznámky.',
        position: 'right',
        spotlight: true
      }
    ],
    CHILD_CATEGORY_SELECT_SCREEN: [
      {
        id: 'child_select',
        target: 'child_select',
        title: 'Přesměrování na dítě',
        content: 'Kliknutím se dostanete k dítěti.',
        position: 'left',
        spotlight: true
      },
      {
        id: 'roll_options_select',
        target: 'roll_options',
        title: 'Filtrování dat',
        content: 'Kliknutím rozbalíte možnosti pro filtrování a výběr rozšířených úkolů.',
        position: 'bottom',
        spotlight: true
      },
      {
        id:"select_all",
        target:"select_all",
        title:"Výběr všech úkolů",
        content:'Kliknutím vyberete všechny úkoly.',
        position:'left',
        spotlight:true
      },
      {
        id:'task-list-item',
        target:'task-list-item',
        title:'Detail úkolu',
        content:'Kartička úkolu, zde můžete přímo zakliknout hodnocení, označit a vybrat více hodnocení.',
        position:'top',
        spotlight:true
      },
      {
        id:'history_button',
        target:'history_button',
        title:'Historie hodnocení',
        content:'Kliknutím se dostanete k historii hodnocení dítěte pro vybraný úkol.',
        position:'left',
        spotlight:true
      }, 
      {
        id:'task-list-item-option',
        target:'task-list-item-option',
        title:'Detail úkolu',
        content:'Zde můžete přímo zakliknout hodnocení a budete přesměrování na vyplňovací stránku.',
        position:'right',
        spotlight:true
      },
        {
          id: 'multi_fab_button',
          target: 'multi_fab_button',
          title: 'Rychlé akce',
          content: 'Přidání nového hodnocení nebo poznámky.',
          position: 'left',
          spotlight: true
        }
    ],
    CHILD_NOTES_SCREEN: [],
    ASSESSMENTS_SCREEN: [
      {
        id:'search_bar',
        target:'search_bar',
        title:'Vyhledávání',
        content:'Rychle vyhledejte hodnocení dítěte podle jména, příjmení nebo názvu úkolu.',
        position:'left',
        spotlight:true
      },
      {
        id: 'assessment_card',
        target: 'assessment_card',
        title: 'Karta hodnocení',
        content: 'Kliknutím se dostanete k editaci hodnocení.',
        position: 'top',
        spotlight: true
      },  
      {
        id:'child_touchable',
        target:'child_touchable',
        title:'Přesměrování na dítě',
        content:'Kliknutím se dostanete k přehledu dítěte.',
        position:'left',
        spotlight:true
      },
      
    ],
    EDIT_ASSESSMENT_SCREEN: [
      {
        id:'menu-delete',
        target:'menu-delete',
        title:'Smazat hodnocení',
        content:'Kliknutím smažete hodnocení dítěte.',
        position:'left',
        spotlight:true
      },
      {
        id:"date_picker",
        target:"date_picker",
        title:"Datum hodnocení",
        content:'Kliknutím na datum se dostanete k výběru datumu.',
        position:'right',
        spotlight:true
      },
      {
        id: 'assessment_card',
        target: 'assessment_card',
        title: 'Karta editace hodnocení',
        content: 'Můžete upravit hodnocení, smazat hodnocení, přidat/upravit poznámku.',
        position: 'top',
        spotlight: true
      },
      {
        id:'child_touchable',
        target:'child_touchable',
        title:'Přesměrování na dítě',
        content:'Kliknutím se dostanete k přehledu dítěte.',
        position:'left',
        spotlight:true
      },
      {
        id: 'assessment-type-picker',
        target: 'assessment-type-picker',
        title: 'Hodnocení',
        content: 'Zde můžete upravit hodnocení kliknutím kartu.',
        position: 'top',  
        spotlight: true
      },
      {
        id: 'note_input',
        target: 'note_input',
        title: 'Poznámka',
        content: 'Zde můžete upravit/smazat/přidat poznámku kliknutím na textový pole.',
        position: 'top',
        spotlight: true
      }

    ],
    CREATE_ASSESSMENT_SCREEN: [
      {
        id:'date_picker',
        target:"date_picker",
        title:"Datum hodnocení",
        content:'Kliknutím na datum se zobrazí datum výběru a můžete ho upravit.',
        position:'right',
        spotlight:true
      },
      {
        id: 'child_button',
        target: 'child_button',
        title: 'Přidat děti',
        content: 'Kliknutím se dostanete k výběru dětí, můžete přidat více dětí.',
        position: 'left',
        spotlight: true
      },
      {
        id: 'task_button',
        target: 'task_button',
        title: 'Přidat úkol',
        content: 'Kliknutím se dostanete k výběru oblastí pro úkol, můžete přidat více úkolů.',
        position: 'left',
        spotlight: true
      },
    ],
    EDIT_CHILD_NOTE_SCREEN: [],
    
  };

  export const NEED_SCROLL = [
    '',
  ];