import React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, StyleSheet, View } from 'react-native';
import { Subheading } from 'react-native-paper';
import { CreateAssessmentProps } from '../lib/navigation';
import { theme } from '../theme';
import { CustomHeader } from '../components/layout/CustomHeader';
import { ChildListItem } from '../components/child/ChildListItem';
import { useBackButtonAlert } from '../hooks/use-back-button-alert';
import { Spinner } from '../components/ui/Spinner';
import { AssessmentsRenderContent } from '../components/create-assessments/AssessmentsRenderContent';
import { ListHeader } from '../components/create-assessments/ListHeader';
import useAsyncToast from '../hooks/use-async-toast';
import WebContainer from '../components/ui/WebContainer';
import { isWeb } from '../lib/is-web';
import { useParseUrlParams } from '../components/create-assessments/hooks/use-parse-url-params';
import { useAssessmentState } from '../components/create-assessments/hooks/use-assessment-state';
import { useAssessmentOperations } from '../components/create-assessments/hooks/use-assessment-operation';
import { AssessmentButton } from '../components/create-assessments/AsessmentButton';
import { AssessmentDialogs } from '../components/create-assessments/AssessmentDialogs';
import { useHelperDialog } from '../components/create-assessments/hooks/use-helper-dialog';
import { Background } from '../components/ui/Background';
import { ScrollView } from 'react-native-gesture-handler';

export const CreateAssessmentScreen: React.FC<CreateAssessmentProps> =
  React.memo(({ route, navigation }) => {
    const isFocused = useIsFocused();
    const {
      classId: classIdParam,
      childIds: childIdsParam,
      categoryId: categoryIdParam,
      taskIds: taskIdsParam,
      selectedOption,
    } = route.params;

    const parseUrlParams = useParseUrlParams(
      classIdParam,
      childIdsParam,
      taskIdsParam,
      categoryIdParam,
    );
    const state = useAssessmentState(
      Number(classIdParam) || 0,
      Number(categoryIdParam) || -1,
    );
    const operations = useAssessmentOperations(
      state.setTaskIds,
      state.setTempTaskIds,
      state.setTempChildIds,
      state.setChildIds,
      state.categoryTasks,
    );

    const onHelperNavigateDialog = useHelperDialog({
      taskIds: state.taskIds,
      childIds: state.childIds,
      isDisabled: state.isDisabled,
      onSave: state.onSave,
      navigation,
    });

    const [displayedAssessmentsCount, setDisplayedAssessmentsCount] =
      React.useState(5);
    const displayedAssessments = Object.values(state.assessments).slice(
      0,
      displayedAssessmentsCount,
    );

    React.useEffect(() => {
      if (isFocused) {
        const { classId, childIds, taskIds, categoryId } = parseUrlParams();
        state.setClassId(classId);
        state.setChildIds(childIds);
        state.setTempChildIds(childIds);
        state.setTaskIds(taskIds);
        state.setTempTaskIds(taskIds);
        state.setCategoryId(categoryId);
      }
    }, [isFocused, parseUrlParams]);

    React.useEffect(() => {
      if (!isFocused || !selectedOption || !taskIdsParam?.length) {
        return;
      }
      const taskId = taskIdsParam[0];
      if (typeof taskId === 'number') {
        state.setAssessments((prev) => ({
          ...prev,
          [taskId]: {
            task: taskId,
            option: selectedOption,
            note: '',
          },
        }));
      }
    }, [isFocused, selectedOption, taskIdsParam]);


    const onActiveNote = React.useCallback(
      (id: number) => {
        state.setIsNoteIdActive((ids: number[]) => {
          if (ids.includes(id)) {
            return ids.filter((x) => x !== id);
          } else {
            return [...ids, id];
          }
        });
      },
      [state.setIsNoteIdActive],
    );

    useAsyncToast(state.childIds.length > 1, 'multipleChildren', {
      title: 'Upozornění',
      message: 'U více dětí se uložené úkoly uloží všem stejně',
      type: 'info',
      time: 7000,
    });

    useBackButtonAlert(
      state.selectedData,
      'Máte neuložené změny',
      'Opravdu chcete odejít?',
    );

    const loadMoreAssessments = () => {
      if (displayedAssessmentsCount < Object.values(state.assessments).length) {
        setDisplayedAssessmentsCount((prevCount) => prevCount + 10);
      }
    };

    const renderChildItem = React.useCallback(
      ({ item: childId, key }: { item: number; key: number }) => {
        if (!state.children[childId]) {
          console.warn(`Child with id ${childId} not found in children state`);
          return null;
        }
        return (
          <ChildListItem
            key={key}
            deleteMode
            item={state.children[childId]}
            onPressCheck={() =>
              state.setChildIds((ids) => ids.filter((x) => x !== childId))
            }
          />
        );
      },
      [state.children],
    );

    const renderAssessmentItem = React.useCallback(
      ({
        item: assessment,
      }: {
        item: { task: number; option: number; note: string };
      }) => {
        const task = state.tasks[assessment.task];
        const assessmentType =
          state.assessmentTypes[task?.assessment_type || -1];
        const activeNote = state.isNoteIdActive.find(
          (active) => assessment.task === active,
        );

        return (
          <AssessmentsRenderContent
            assessment={assessment}
            assessmentType={assessmentType}
            activeNote={activeNote}
            onActiveNote={onActiveNote}
            onRemoveTasks={operations.onRemoveTasks}
            setAssessments={state.setAssessments}
            task={task}
          />
        );
      },
      [
        state.tasks,
        state.assessmentTypes,
        state.isNoteIdActive,
        operations.onRemoveTasks,
      ],
    );

    return (
      <View style={styles.container}>
      <CustomHeader
        title={<Subheading style={styles.white}>Přidat hodnocení</Subheading>}
        menu={null}
      />
      
      <ScrollView style={styles.scrollView}>
        <WebContainer>
          <FlatList
            initialNumToRender={isWeb ? 90 : 20}
            style={{ padding: isWeb ? 2 : 13 }}
            removeClippedSubviews={true}
            scrollEnabled={false} // Disable scroll on FlatList since we're using ScrollView
            data={
              isWeb ? Object.values(state.assessments) : displayedAssessments
            }
            keyExtractor={(item, i) => `assessment-${item?.task} ${i}`}
            onEndReached={loadMoreAssessments}
            onEndReachedThreshold={0.4}
            ListHeaderComponent={
              <ListHeader
                {...{
                  classId: state.classId,
                  date: state.date,
                  setDate: state.setDate,
                  setTempChildIds: state.setTempChildIds,
                  childIds: state.childIds,
                  setChildOpen: state.setChildOpen,
                  onRemoveAllChildren: operations.onRemoveAllChildren,
                  renderChildItem,
                  setCategoryOpen: state.setCategoryOpen,
                  setTempTaskIds: state.setTempTaskIds,
                  taskIds: state.taskIds,
                  setTaskOpen: state.setTaskOpen,
                  onRemoveAllTasks: operations.onRemoveAllTasks,
                  categoryId: state.categoryId,
                }}
              />
            }
            ListFooterComponent={
              <>
                {!isWeb &&
                displayedAssessmentsCount <
                  Object.values(state.assessments).length ? (
                  <Spinner size="small" color={theme.colors.primary} />
                ) : null}
                <View
                  style={{
                    height: state.isNoteIdActive.length >= 1 ? 320 : 100,
                  }}
                />
              </>
            }
            renderItem={renderAssessmentItem}
          />
          <AssessmentDialogs {...state} {...operations} />
        </WebContainer>
      </ScrollView>
        <AssessmentButton
          isDisabled={state.isDisabled}
          loading={state.loading}
          onPress={onHelperNavigateDialog}
        />
    </View>
    );
  });

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  scrollView: {
    flex: 1,
  },
  white: {
    color: 'white',
  },
  fixedButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    right: 0,
    zIndex: 100,
    alignItems: 'center',
    backgroundColor: 'transparent',
  }
});
