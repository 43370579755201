import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Caption, Card, Subheading, Text, Divider } from 'react-native-paper';
import { Components } from '../../server';
import { format } from 'date-fns';
import { DifficultyIcon } from '../../components/ui/DifficultyIcon';
import { colors } from '../../theme';
import IconWrapper from '../../components/ui/IconWrapper';
import { setBgColor, setBorderColor } from '../../lib/set-color';
import ChildTypeGender from '../../components/child/ChildTypeGender';
import { ChildType } from '../../store';
import FadeInFromLeftOrRight from '../../components/animations/FadeInFromLeftOrRightAnimation';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { Dimensions } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props {
  onPressEdit: () => void;
  onPressChild: () => void;
  option:
    | {
        id: number;
        label: string;
      }
    | undefined;
  isMaleGender: boolean;
  item?: Components.Schemas.Assessment;
  user?: Components.Schemas.User;
  child?: ChildType;
  task?: Components.Schemas.Task;
  direction?: 'left' | 'right';
  Icon: any;
  categoryLabel?: string;
  delay?: number;
}

const AssessmentCard = ({
  option,
  isMaleGender,
  item,
  user,
  child,
  task,
  Icon,
  categoryLabel,
  direction,
  onPressEdit,
  onPressChild,
  delay = 50,
}: Props) => {
  const [isPressed, setIsPressed] = React.useState(false);

  return (
    <FadeInFromLeftOrRight duration={400} delay={delay} direction={direction}>
      <Card
        id='assessment_card'
         style={[
          {
            marginVertical: 8,
            backgroundColor:  colors.white,
            borderColor: isMaleGender ? colors.blue : colors.darkRed,
            borderWidth: 1,
            opacity: isPressed ? 0.5 : 1,
            transform: [{ scale: isPressed ? 0.98 : 1 }],
          },
          styles.cardShadow,
        ]}
        elevation={isPressed ? 1 : 2}
        onPress={onPressEdit}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
      >
        <Card.Content style={{ gap: 5 }}>
          <View style={[styles.row, styles.between]}>
            <View>
              <Caption>
                {format(new Date(item?.date_of_assessment ?? ''), 'd. M. yyyy')}
              </Caption>
              <Caption style={styles.text}>
                {user?.first_name || item?.assessed_by
                  ? `Hodnotil(a) ${user?.first_name} ${user?.last_name}`
                  : ''}
              </Caption>
            </View>
            <View style={styles.headerRight}>
                {categoryLabel && (
                  <Caption style={{ fontSize: 13 }}>{categoryLabel}</Caption>
                )}
                {Icon && <Icon style={styles.icon} />}
              </View>
          </View>
          <Divider
            style={{
              backgroundColor: isMaleGender
                ? colors.veryLightBlue
                : colors.veryLightDarkRed,
              marginVertical: 2,
            }}
          />
          <View style={styles.row}>
            <View style={styles.content}>
                    <TouchableOpacity
                      
                      onPress={onPressChild}
                      style={styles.childTouchable}
                      activeOpacity={0.7}
                    >
                      <ChildTypeGender
                        isGender={isMaleGender}
                        child={child}
                        isChevronRight
                      />
                    </TouchableOpacity>
              <Divider
                style={{
                  backgroundColor: isMaleGender
                    ? colors.veryLightBlue
                    : colors.veryLightDarkRed,
                  marginVertical: 2,
                }}
              />
              <View style={[styles.row, { alignItems: 'center', gap: 8 }]}>
                <DifficultyIcon difficulty={task?.difficulty} />
                <Subheading style={styles.desc}>{task?.task_description}</Subheading>
              </View>

              <View style={{ marginVertical: 4, gap: 6 }}>
                <BubbleScaleUpAnimation delay={450} duration={400}>
                  <TouchableOpacity onPress={onPressEdit}>
                    <View
                      style={{
                        flexDirection: 'row',
                        gap: 4,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        borderColor: setBorderColor(option),

                        borderWidth: 2,
                        borderRadius: 10,
                        paddingHorizontal: 3,
                        paddingVertical: 3,
                        backgroundColor: setBgColor(option),
                      }}
                    >
                      <Text
                        style={[
                          styles.assessment,
                          { backgroundColor: setBgColor(option) },
                        ]}
                      >
                        {option?.label}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </BubbleScaleUpAnimation>
                <View style={styles.containerNote}>
                  {item?.note ? (
                    <IconWrapper
                      delay={500}
                      iconSize={18}
                      color={setBorderColor(option)}
                      bgColor={setBgColor(option)}
                      width={40}
                      height={40}
                      icon={'note-multiple-outline'}
                    >
                      <Text style={styles.text}>{item.note}</Text>
                    </IconWrapper>
                  ) : null}
                 
                </View>
               
              </View>
            </View>
          </View>
          <MaterialCommunityIcons 
                    name="pencil-outline" 
                    size={20} 
                    color={isMaleGender ? colors.blue : colors.darkRed}
                    style={styles.pencilIcon}
          />
        </Card.Content>
      </Card>
    </FadeInFromLeftOrRight>
  );
};

export default AssessmentCard;

const deviceWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  cardShadow: {
    position: 'relative',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },
  flex: {
    flex: 1,
    gap: 5,
  },
  text: {
    maxWidth: deviceWidth - 200,
  },
  childTouchable: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    borderRadius: 8,
    padding: 4,
  },
  between: {
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    marginVertical: 0,
    gap: 6,
  },
  desc: {
    fontSize: 15,
    paddingTop: 3,
  },
  icon: {
    alignSelf: 'center',
    marginHorizontal: 5,
    width: 40,
    height: 40,
  },
  pencilIcon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  assessment: {
    fontSize: 15,
    backgroundColor: 'white',
    padding: 5,
    textAlign: 'center',
  },

  containerNote: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});
