import React from 'react';
import { Button, Card } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { theme } from '../../theme';

interface Props {
  onPress: () => void;
  id?: string;
  text: string;
  icon: string;
}

export const CardButton = React.memo(function ({ onPress, text, icon, id }: Props) {
  return (
    <Card id={id} elevation={2} style={styles.card}>
      <Button
        icon={() => {
          return <Icon name={icon} size={24} color={theme.colors.primary} />;
        }}
        labelStyle={{ fontSize: 16 }}
        contentStyle={styles.buttonContentStyle}
        onPress={onPress}
      >
        {text}
      </Button>
    </Card>
  );
});

const styles = StyleSheet.create({
  card: {
    borderColor: theme.colors.primary,
    borderWidth: 1,
  },
  buttonContentStyle: {
    flexDirection: 'row',
    height: 48,
    borderRadius: 10,
  },
});
