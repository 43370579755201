import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Dialog, IconButton } from 'react-native-paper';
import { colors, theme } from '../../theme';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import { isWeb } from '../../lib/is-web';
import { TouchableOpacity } from 'react-native-gesture-handler';

interface Props {
  name: string | undefined;
  showData: boolean;
  setShowData: React.Dispatch<React.SetStateAction<boolean>>;
  style?: ViewStyle;
  text?: string;
}

const RollOptions = ({ name, showData, setShowData, style, text }: Props) => {
  return (
    <FadeInFromTopAnimation delay={50}>
      <TouchableOpacity onPress={() => setShowData((prev) => !prev)} style={[styles.header, style]} id="roll_options">
        <Dialog.Title style={{ fontSize: 15, margin: 0 }}>
          {text || "Podkategorie a rozšířující úkoly"}  
        </Dialog.Title>
        <IconButton
          id='roll_options_select'
          style={{
            backgroundColor: colors.white,
            marginLeft: 'auto',
          }}
          iconColor={theme.colors.primary}
          icon={showData ? 'chevron-up' : 'chevron-down'}
    
        />
      </TouchableOpacity>
    </FadeInFromTopAnimation>
  );
};

export default RollOptions;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    
    ...(isWeb ? {
      cursor: 'pointer',
    } : {}),
    paddingVertical: isWeb ? 10 : 0,
    paddingHorizontal: isWeb ? 16 : 2,
    marginRight: 5,
  },
});
