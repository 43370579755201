import React from 'react';
import { useNavigationState } from '@react-navigation/native';
import TourGuide from './TourGuide';
import { useTourControl } from './TourGuideControlProvider';
import { TOUR_STEPS } from './TourGuideSteps';
import { View } from 'react-native';
import { useAuth } from '../../hooks/use-auth';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { TourWelcomeDialog } from './TourGuideInitialDialog';

type TourScreen = keyof typeof TOUR_STEPS;

interface RouteMapping {
  path: string;
  getTourScreen: (isDesktop: boolean) => TourScreen;
}

const ROUTE_MAPPINGS: Record<string, RouteMapping> = {
  ClassHome: {
    path: 'ClassHome',
    getTourScreen: (isDesktop) => isDesktop ? 'CLASS_HOME_SCREEN' : 'CLASS_HOME_SCREEN_MOBILE'
  },
  Class: {
    path: 'Class',
    getTourScreen: () => 'CLASS_CHILDREN_SCREEN'
  },
  ClassChildren: {
    path: 'ClassChildren',
    getTourScreen: () => 'CLASS_CHILDREN_SCREEN'
  },
  ClassCategorySelect: {
    path: 'ClassCategorySelect',
    getTourScreen: () => 'CLASS_CATEGORY_SCREEN'
  },
  ClassStats: {
    path: 'ClassStats',
    getTourScreen: () => 'CLASS_STATS_SCREEN'
  },
  ClassNotes: {
    path: 'ClassNotes',
    getTourScreen: () => 'CLASS_NOTES_SCREEN'
  },  
  Assessments: {
    path: 'Assessments',
    getTourScreen: () => 'ASSESSMENTS_SCREEN'
  },
  CreateAssessment: {
    path: 'CreateAssessment',
    getTourScreen: () => 'CREATE_ASSESSMENT_SCREEN'
  },
  EditClassroomNote: {
    path: 'EditClassroomNote',
    getTourScreen: () => 'EDIT_CLASSROOM_NOTE'
  },
  EditAssessment: {
    path: 'EditAssessment',
    getTourScreen: () => 'EDIT_ASSESSMENT_SCREEN'
  },
  EditChildNote: {
    path: 'EditChildNote',
    getTourScreen: () => 'EDIT_CHILD_NOTE_SCREEN'
  }, 
  ChildOverview: {
    path: 'ChildOverview',
    getTourScreen: () => 'CHILD_OVERVIEW_SCREEN'
  },
  ChildCategory: {
    path: 'ChildCategory',
    getTourScreen: () => 'CHILD_CATEGORY_SELECT_SCREEN'
  },
  ChildNotes: {
    path: 'ChildNotes',
    getTourScreen: () => 'CHILD_NOTES_SCREEN'
  } 

} as const;

interface GlobalTourWrapperProps {
  children: React.ReactNode;
}

export const getTourScreenFromRoute = (
  route: string, 
  isDesktop: boolean
): TourScreen | null => {
  const mapping = ROUTE_MAPPINGS[route];
  return mapping ? mapping.getTourScreen(isDesktop) : null;
};

export const GlobalTourWrapper: React.FC<GlobalTourWrapperProps> = ({ children }) => {
  const { isTourEnabled, isInitialized } = useTourControl();
  const navigationState = useNavigationState(state => state);
  const [currentScreen, setCurrentScreen] = React.useState<TourScreen | ''>('');
  const { accessToken } = useAuth();
  const { isDesktop } = useDesktopStyles();

  const getActiveRouteName = (state: any): string => {
    if (!state?.routes?.length) return '';

    const route = state.routes[state.index || (state.routes.length - 1)];
    
    if (route.state) {
      return getActiveRouteName(route.state);
    }

    if (route.params?.screen) {
      return route.params.screen;
    }

    if (route.name === 'Child') {
      const childState = route.params?.screen || 'ChildOverview';
      return childState;
    }

    return route.name;
  };


  React.useEffect(() => {
    if (!navigationState) return;

    const routeName = getActiveRouteName(navigationState);
    const mappedScreen = getTourScreenFromRoute(routeName, isDesktop);

    if (mappedScreen && mappedScreen !== currentScreen) {

      setCurrentScreen(mappedScreen);
    }
  }, [navigationState, currentScreen]);


  if (!isInitialized) {
    return <>{children}</>;
  }
  
  if (!isTourEnabled || !currentScreen || !TOUR_STEPS[currentScreen] || !accessToken.current) {
    return <>{children}</>;
  }

  return (
    <View style={{ flex: 1 }}>
      <TourGuide currentScreen={currentScreen}>
        {children}
      </TourGuide>
    </View>
  );
};