import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { alertCross, emailValidator } from '../utils/utils';
import { Background } from '../components/ui/Background';
import { Logo } from '../components/ui/Logo';
import { Button } from '../components/ui/Button';
import { ForgotPasswordProps } from '../lib/navigation';
import { TextInput, Text, Headline, Appbar } from 'react-native-paper';
import { theme } from '../theme';
import { useApi } from '../hooks/use-api';

export const ForgotPasswordScreen = React.memo(function ForgotPasswordScreen({
  navigation,
}: ForgotPasswordProps) {
  const [email, setEmail] = React.useState({ value: '', error: '' });
  const [loggingIn, setLoggingIn] = React.useState(false);
  const { publicAxios } = useApi();

  const _onSendPressed = async () => {
    const error = emailValidator(email.value);

    if (error) {
      setEmail({ ...email, error });
      return;
    }

    try {
      setLoggingIn(true);
      await publicAxios.post<{ access: string; refresh: string }>(
        '/api/reset-password/',
        {
          email: email.value,
        },
      );
      alertCross(
        'Odesláno',
        'Pokud je e-mail platný, tak v e-mailové schránce najdete další pokyny',
      );
      navigation.pop();
    } catch (e) {
      setLoggingIn(false);
      alertCross('Chyba', 'Zadaný účet neexistuje');
    }
  };

  return (
    <Background>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          padding: 20,
          justifyContent: 'center',
        }}
      >
        <Appbar.BackAction onPress={() => navigation.pop()} color="black" />
        <Logo />

        <Headline>Zapomněli jste heslo?</Headline>

        <TextInput
          mode="outlined"
          label="E-mail"
          returnKeyType="done"
          value={email.value}
          onChangeText={(text) => setEmail({ value: text, error: '' })}
          error={!!email.error}
          autoCapitalize="none"
          autoComplete="email"
          textContentType="emailAddress"
          keyboardType="email-address"
        />
        {!!email.error && (
          <Text style={{ fontSize: 14, color: theme.colors.error, padding: 4 }}>
            {email.error}
          </Text>
        )}

        <Button
          mode="contained"
          onPress={_onSendPressed}
          style={styles.button}
          disabled={loggingIn}
        >
          Obnovit heslo
        </Button>
      </ScrollView>
    </Background>
  );
});

const styles = StyleSheet.create({
  back: {
    width: '100%',
    marginTop: 12,
  },
  button: {
    marginTop: 12,
  },
  label: {
    width: '100%',
  },
});
