import React from 'react';
import { Button } from 'react-native-paper';
import { PulsingAnimationInfinite } from '../../components/animations/PulsingAnimationInfinite';
import { theme } from '../../theme';
import { isWeb } from '../../lib/is-web';

interface AssessmentButtonProps {
  isDisabled: boolean;
  loading: boolean;
  onPress: () => void;
}

export const AssessmentButton: React.FC<AssessmentButtonProps> = ({
  isDisabled,
  loading,
  onPress,
}) => (
  <PulsingAnimationInfinite isFilled={!isDisabled}>
    <Button
      mode="contained"
      loading={loading}
      onPress={onPress}
      style={{
        backgroundColor: isDisabled
          ? theme.colors.disabled
          : theme.colors.primary,
        alignSelf: 'center',
        position: 'absolute',
        bottom: 16,
        width: isWeb ? 400 : "80%",
        height: 42,
        justifyContent: 'center',
        marginBottom: 8,
      }}
    >
      Uložit
    </Button>
  </PulsingAnimationInfinite>
);
