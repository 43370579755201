import React from 'react';
import { Searchbar } from 'react-native-paper';
import { colors } from '../../theme';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import WebContainer from './WebContainer';

interface Props {
  onChange: (text: string) => void;
  searchQuery: string;
  delay?: number;
  id?: string;
}

const CustomSearchBar = React.forwardRef(
  ({ onChange, searchQuery, delay, id }: Props, ref: any) => {
    return (
      <WebContainer>
        <FadeInFromTopAnimation delay={delay || 100}>
          <Searchbar
            id={id}
            style={{
              marginHorizontal: 8,
              marginVertical: 8,
              borderColor: colors.orange,
              borderWidth: 1,
              zIndex: 10,
              elevation: 3,
            }}
            inputStyle={{ fontSize: 16 }}
            onIconPress={() => ref?.current?.focus()}
            ref={ref}
            onChangeText={onChange}
            value={searchQuery}
            iconColor={colors.orange}
            placeholder="Hledat"
          />
        </FadeInFromTopAnimation>
      </WebContainer>
    );
  },
);

export default CustomSearchBar;
