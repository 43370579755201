import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Caption, Portal, Subheading } from 'react-native-paper';
import { ChildTaskProps } from '../lib/navigation';
import { useIsFocused } from '@react-navigation/native';
import { useRecoilValue } from 'recoil';
import {
  assessmentTypeState,
  categoriesState,
  childAssessmentsByTaskState,
  childState,
  subcategoriesState,
  taskState,
  usersState,
} from '../store';
import { useMultiFABScroll } from '../components/multifab/MultiFABContext';
import { MultiFAB } from '../components/multifab/MultiFAB';
import { Background } from '../components/ui/Background';
import { Components } from '../server';
import { RefreshUserData } from '../components/RefreshUserData';

import { CustomHeader } from '../components/layout/CustomHeader';
import { HeaderMenu } from '../components/layout/HeaderMenu';

import AssessmentCard from '../components/assessments/AssessmentCard';
import { colors } from '../theme';
import { icons } from '../components/icons';

export const ChildTaskScreen = React.memo(function ChildTaskScreen({
  route,
  navigation,
}: ChildTaskProps) {
  const { childId, taskId } = route.params;
  const isFocused = useIsFocused();
  const task = useRecoilValue(taskState(taskId))!;
  const child = useRecoilValue(childState(childId))!;
  const users = useRecoilValue(usersState);
  const assessments = useRecoilValue(childAssessmentsByTaskState(childId));

  const assessmentType = useRecoilValue(
    assessmentTypeState(task?.assessment_type || 0),
  );
  const categories = useRecoilValue(categoriesState);
  const subcategories = useRecoilValue(subcategoriesState);
  const { setStatus } = useMultiFABScroll();

  const isMaleGender = child?.gender === 'M';

  React.useEffect(() => {
    if (isFocused) {
      setStatus({
        initial: {
          classId: child?.classroom,
          childIds: [childId],
          taskIds: [task?.id],
        },
      });
    }
  }, [isFocused, setStatus, childId, task?.id, child?.classroom]);

  const renderItem = ({ item }: { item: Components.Schemas.Assessment }) => {
    const user = users[item.assessed_by || 0];

    const option = assessmentType?.options?.find((x) => x.id === item.option);
    const Icon =
      icons[
        categories[subcategories[task?.subcategory || 0]?.parent_category || 0]
          ?.label || ''
      ];

    const labelCategory =
      categories[subcategories[task?.subcategory || 0]?.parent_category || 0]
        ?.label || '';

    return (
      <AssessmentCard
        delay={600}
        direction={'left'}
        item={item}
        user={user}
        child={child}
        task={task}
        onPressEdit={() =>
          navigation.navigate('EditAssessment', { assessmentId: item?.id ?? 0 })
        }
        onPressChild={() =>
          navigation.navigate('Child', { childId: child?.id ?? 0 })
        }
        option={option}
        Icon={Icon}
        isMaleGender={isMaleGender}
        categoryLabel={labelCategory}
      />
    );
  };

  return (
    <>
      <CustomHeader
        title={<Subheading style={styles.white}>Hodnocení dítěte</Subheading>}
        menu={<HeaderMenu navigation={navigation} />}
      />
      <Background>
        {!assessments[task?.id]?.length && (
          <View style={styles.emptyState}>
            <Caption style={styles.addAssessment}>Přidejte hodnocení kliknutím na {'"+"'} vpravo dole </Caption>
          </View>
        )}

        <FlatList
          contentContainerStyle={{ padding: 10 }}
          refreshControl={<RefreshUserData />}
          data={assessments[task?.id] ?? []}
          keyExtractor={({ id }) => id.toString()}
          ListFooterComponent={() => {
            return <View style={{ height: 100, width: '100%' }}></View>;
          }}
          renderItem={renderItem}
        />

        <Portal>
          <MultiFAB visible={isFocused} />
        </Portal>
      </Background>
    </>
  );
});

const styles = StyleSheet.create({
  emptyState: {
    margin: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  white: {
    color: 'white',
  },
  classroomLabel: {
    fontSize: 20,
    color: colors.darkRed,
    fontWeight: 'bold',
    marginVertical: 4,
  },
  addAssessment: {
    marginVertical: 8,
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.darkRed,
  },
  icon: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
  },
  assessment: {
    fontSize: 16,
    backgroundColor: 'white',
    padding: 6,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    flex: 1,
    gap: 10,
  },
});
