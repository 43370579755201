import React from 'react';
import { View } from 'react-native';
import { Button, Menu } from 'react-native-paper';
import { useRecoilValue } from 'recoil';
import { classesState, classState } from '../../store';

interface ClassHeaderProps {
  selected: number;
  selectClass: (classId: number) => void;
}

export const ClassHeader = React.memo(function ClassHeader(
  props: ClassHeaderProps,
) {
  const classrooms = useRecoilValue(classesState);
  const classroom = useRecoilValue(classState(props.selected));
  const [visible, setVisible] = React.useState(false);

  return (
    <Menu
      visible={visible}
      style={{ marginTop: '12%' }}
      onDismiss={() => setVisible(false)}
      anchor={
        <View style={{ alignItems: 'flex-start' }}>
          <Button
            id="class_header"
            icon={visible ? 'chevron-up' : 'chevron-down'}
            textColor="white"
            onPress={() => setVisible(true)}
            contentStyle={{ flexDirection: 'row-reverse' }}
            uppercase={false}
          >
            {classroom?.label}
          </Button>
        </View>
      }
    >
      {Object.values(classrooms).map((x) => (
        <Menu.Item
          key={x.id}
          title={x.label}
          onPress={() => {
            props.selectClass(x.id);
            setVisible(false);
          }}
        />
      ))}
    </Menu>
  );
});
