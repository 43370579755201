
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, Animated } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NEED_SCROLL, TOUR_STEPS } from './TourGuideSteps';
import { theme } from '../../theme';
import { useTourControl } from './TourGuideControlProvider';
import useIsMobile from '../../hooks/use-is-mobile';

interface TourGuideProps {
  currentScreen: keyof typeof TOUR_STEPS;
  children: React.ReactNode;
}

interface Position {
    x: number;
    y: number;
    width: number;
    height: number;
}

const TourGuide: React.FC<TourGuideProps> = ({ currentScreen, children }) => {
    const isMobile = useIsMobile(); 
    const { isTourEnabled } = useTourControl();
    const [currentStep, setCurrentStep] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState<Position>({ x: 0, y: 0, width: 0, height: 0 });
    const fadeAnim = React.useRef(new Animated.Value(0)).current;
    const spotlightAnim = React.useRef(new Animated.Value(0)).current;
    const [pageHeight, setPageHeight] = useState(0);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const positionCheckTimer = React.useRef<any>(null);



    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLayoutReady(true);
        }, 500);

        const resizeObserver = new ResizeObserver(() => {
          setPageHeight(Math.max(
            document.documentElement.scrollHeight,
            document.body.scrollHeight
          ));
        });
        resizeObserver.observe(document.documentElement);
        
        return () => {
          clearTimeout(timer);
          resizeObserver.disconnect();
        };
      }, [isVisible]);

    useEffect(() => {
        if (isLayoutReady) {
            checkTourStatus();
        }
    }, [isLayoutReady, currentScreen, isTourEnabled]);
  
    useEffect(() => {
        const checkTourSetting = async () => {
            const tourEnabled = await AsyncStorage.getItem('tour_enabled');
            if (tourEnabled === 'true') {
                const hasSeenTour = await AsyncStorage.getItem(`tour_completed_${currentScreen}`);
                if (!hasSeenTour) {
                    setCurrentStep(0);
                    showTour();
                }
            } else if (isVisible) {
                hideTour();
            }
        };

        window.addEventListener('storage', checkTourSetting);
        
        return () => {
            window.removeEventListener('storage', checkTourSetting);
        };
    }, [currentScreen]);

    const checkTourStatus = async () => {
      try {
          if (isTourEnabled) {
              const hasSeenTour = await AsyncStorage.getItem(`tour_completed_${currentScreen}`);
              if (!hasSeenTour) {
                  setCurrentStep(0);
                  showTour();
              }
          } else if (isVisible) {
              hideTour();
          }
      } catch (error) {
          console.error('Error checking tour status:', error);
      }
  };
  
    useEffect(() => {
      if (isVisible) {
        updateTooltipPosition();
      }
    }, [currentStep, isVisible]);


    useEffect(() => {
        if (isVisible) {
            if (positionCheckTimer.current) {
                clearInterval(positionCheckTimer.current);
            }
            positionCheckTimer.current = setInterval(() => {
                updateTooltipPosition();
            }, 100);

            setTimeout(() => {
                if (positionCheckTimer.current) {
                    clearInterval(positionCheckTimer.current);
                }
            }, 1000);
        }

        return () => {
            if (positionCheckTimer.current) {
                clearInterval(positionCheckTimer.current);
            }
        };
    }, [currentScreen, isVisible]);

    const getMobileTooltipPosition = (rect: DOMRect, position: string, scrollY: number) => {
      const tooltipWidth = 240;
      const tooltipHeight = 150;
      const margin = 10;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let x = (windowWidth - tooltipWidth) / 2; 
      let y = rect.y + scrollY + rect.height + 20; 
    
      if (y + tooltipHeight > window.scrollY + windowHeight) {
        y = Math.max(
          window.scrollY + margin,
          Math.min(rect.y + scrollY - tooltipHeight - 20, window.scrollY + windowHeight - tooltipHeight - margin)
        );
      }
    
      x = Math.max(margin, Math.min(x, windowWidth - tooltipWidth - margin));
    
      return { x, y };
    };

    const updateTooltipPosition = React.useCallback(() => {
        if (!TOUR_STEPS[currentScreen]) return;
      const step = TOUR_STEPS[currentScreen][currentStep];
      if (!step) return;
      
      const scrollY = window.scrollY || window.pageYOffset;
      const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
      
      // Special handling for FAB button (HACK)
      if (step.target === 'multi_fab_button') {
        const fabElement = document.querySelector('[data-testid="fab-content"]');
        if (fabElement) {
            const rect = fabElement.getBoundingClientRect();
            const fabPosition = {
                x: rect.x,
                y: rect.y + scrollY,
                width: rect.width,
                height: rect.height
            };

            let tooltipX = fabPosition.x;
            let tooltipY = fabPosition.y;

            switch (step.position) {
                case 'left':
                    tooltipX = fabPosition.x - 320;
                    break;
                case 'right':
                    tooltipX = fabPosition.x + fabPosition.width + 10;
                    break;
                case 'top':
                    tooltipY = fabPosition.y - 150;
                    break;
                case 'bottom':
                    tooltipY = fabPosition.y + fabPosition.height + 10;
                    break;
            }

            tooltipX = Math.max(20, Math.min(tooltipX, windowWidth - 420));
            tooltipY = Math.max(20, Math.min(tooltipY, windowHeight + scrollY - 150));

            setTooltipPosition({
                x: tooltipX,
                y: tooltipY,
                width: fabPosition.width,
                height: fabPosition.height
            });
        }
        return;
    }

      const targetElement = document.getElementById(step.target);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        
        if (isMobile) {
          const mobilePosition = getMobileTooltipPosition(rect, step.position, scrollY);
          if (!mobilePosition) return;
          setTooltipPosition({
            x: mobilePosition.x,
            y: mobilePosition.y,
            width: rect.width,
            height: rect.height
          });
          return;
        }

        let position: Position = {
          x: rect.x,
          y: rect.y + scrollY,
          width: rect.width,
          height: rect.height
        };
  
        switch (step.position) {
            case 'bottom':
                position.y = rect.y + scrollY + rect.height + 10;
                break;
            case 'top':
                position.y = rect.y + scrollY - 150;
                break;
            case 'left':
                position.x = rect.x - 320;
                position.y = rect.y + scrollY;
                break;
            case 'right':
                position.x = rect.x + rect.width + 10;
                position.y = rect.y + scrollY;
                break;
        }

        position.x = Math.max(20, Math.min(position.x, windowWidth - 320));
        position.y = Math.max(20, Math.min(position.y, windowHeight + scrollY - 150));

        setTooltipPosition(position);
      }
    }, [currentScreen, currentStep]);
  
    const showTour = () => {
        setTimeout(() => {
            setIsVisible(true);
            updateTooltipPosition();
            Animated.parallel([
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 300,
                    useNativeDriver: true,
                }),
                Animated.timing(spotlightAnim, {
                    toValue: 1,
                    duration: 300,
                    useNativeDriver: true,
                })
            ]).start();
        }, 800);
    };
  
    const hideTour = async () => {
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(spotlightAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        })
      ]).start(() => {
        setIsVisible(false);
      });
      
      try {
        await AsyncStorage.setItem(`tour_completed_${currentScreen}`, 'true');
      } catch (error) {
        console.error('Error saving tour status:', error);
      }
    };
  
    const nextStep = () => {
      if (!TOUR_STEPS[currentScreen]) return;
      if (currentStep < TOUR_STEPS[currentScreen].length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        hideTour();
      }
    };

    const Arrow: React.FC<{ position: 'top' | 'bottom' | 'left' | 'right' }> = ({ position }) => {
        const getStyles = () => {
          const baseStyles = {
            position: 'absolute',
            width: 0,
            height: 0,
            borderWidth: 6, 
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          };
    
          switch (position) {
            case 'bottom':
              return {
                ...baseStyles,
                bottom: -6, 
                left: '50%',
                marginLeft: -6,
                borderTopColor: 'white',
              };
            case 'top':
              return {
                ...baseStyles,
                top: -6, 
                left: '50%',
                marginLeft: -6,
                borderBottomColor: 'white',
              };
            case 'left':
              return {
                ...baseStyles,
                left: -6, 
                top: '50%',
                marginTop: -6,
                borderRightColor: 'white',
              };
            case 'right':
              return {
                ...baseStyles,
                right: -6,
                top: '50%',
                marginTop: -6,
                borderLeftColor: 'white',
              };
          }
        };
    
        return <View style={getStyles() as any} />;
    };
  
    const renderTooltip = () => {
      if (!isVisible || !TOUR_STEPS[currentScreen]) return null;
  
      const step = TOUR_STEPS[currentScreen][currentStep];
      
      if (!step) return null;
      return (
        <Animated.View
          style={[
            styles.tooltipContainer,
            {
              opacity: fadeAnim,
              transform: [{
                translateY: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0],
                }),
              }],
              left: tooltipPosition.x,
              top: tooltipPosition.y,
            },
          ]}
        >
         <Arrow position={step.position} />
          <Text style={styles.title}>{step.title}</Text>
          <Text style={styles.content}>{step.content}</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity 
              style={styles.button} 
              onPress={hideTour}
            >
              <Text style={styles.buttonText}>Přeskočit</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.button, styles.nextButton]} 
              onPress={nextStep}
            >
              <Text style={[styles.buttonText, styles.nextButtonText]}>
                {currentStep === TOUR_STEPS[currentScreen].length - 1 ? 'Dokončit' : 'Další'}
              </Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      );
    };
  
    const renderOverlay = () => {
      if (!isVisible || !TOUR_STEPS[currentScreen]) return null;
    
      const step = TOUR_STEPS[currentScreen][currentStep];
      if (!step) return null;
    
      let targetElement;
      if (step.target === 'multi_fab_button') {
        targetElement = document.querySelector('[data-testid="fab-content"]');
      } else {
        targetElement = document.getElementById(step.target);
      }
    
      if (!targetElement) return null;
      
      const rect = targetElement.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;

      const fullHeight = pageHeight;
    


      // Special handling for FAB button (HACK)

      if (step.target === 'multi_fab_button' && isMobile) {
        const fabElement = document.querySelector('[data-testid="fab-content"]');
        const fabRect = fabElement?.getBoundingClientRect();
        if (!fabRect) return null;
      
        return (
          <View style={[styles.overlayContainer, { height: fullHeight }]}>
            {/* Full page overlay */}
            <Animated.View
              style={[
                styles.overlay,
                {
                  opacity: fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 0.7],
                  }),
                  height: fullHeight,
                },
              ]}
            />
          </View>
        );
      }


      if (step.target === 'multi_fab_button') {
        const fabPosition = {
          left: rect.x - 11,
          top: rect.y - 5 + 127,
          width: rect.width + 24,
          height: rect.height + 24,
        };
      
        return (
          <View style={[styles.overlayContainer, { height: fullHeight }]}>
            {/* Top overlay */}
            <Animated.View
              style={[
                styles.overlay,
                {
                  opacity: fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 0.7],
                  }),
                  height: fabPosition.top,
                },
              ]}
            />
            {/* Bottom overlay */}
            <Animated.View
              style={[
                styles.overlay,
                {
                  opacity: fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 0.7],
                  }),
                  top: fabPosition.top + fabPosition.height,
                  height: fullHeight - (fabPosition.top + fabPosition.height),
                },
              ]}
            />
            {/* Left overlay */}
            <Animated.View
              style={[
                styles.overlay,
                {
                  opacity: fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 0.7],
                  }),
                  top: fabPosition.top,
                  width: fabPosition.left,
                  height: fabPosition.height,
                },
              ]}
            />
            {/* Right overlay */}
            <Animated.View
              style={[
                styles.overlay,
                {
                  opacity: fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 0.7],
                  }),
                  left: fabPosition.left + fabPosition.width,
                  top: fabPosition.top,
                  width: Dimensions.get('window').width - (fabPosition.left + fabPosition.width),
                  height: fabPosition.height,
                },
              ]}
            />
          </View>
        );
      }
    
      return (
        <View style={[styles.overlayContainer, { height: fullHeight }]}>
          {/* Top overlay */}
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.7],
                }),
                height: rect.y + scrollY, 
              },
            ]}
          />
          {/* Bottom overlay */}
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.7],
                }),
                top: rect.y + scrollY + rect.height, 
                height: fullHeight - (rect.y + scrollY + rect.height),
              },
            ]}
          />
          {/* Left overlay */}
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.7],
                }),
                top: rect.y + scrollY,
                width: rect.x,
                height: rect.height,
              },
            ]}
          />
          {/* Right overlay */}
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.7],
                }),
                left: rect.x + rect.width,
                top: rect.y + scrollY,
                width: Dimensions.get('window').width - (rect.x + rect.width),
                height: rect.height,
              },
            ]}
          />
        </View>
      );
    };

    const renderMask = () => {
        if (!isVisible || !TOUR_STEPS[currentScreen]) return null;
        const step = TOUR_STEPS[currentScreen][currentStep];
        if (!step) return null;

        if (step.target === 'multi_fab_button') {
          const fabElement = document.querySelector('[data-testid="fab-content"]');
          const fabRect = fabElement?.getBoundingClientRect();
          if (!fabRect) return null;
          return (
              <Animated.View
                  style={[
                      styles.spotlight,
                      {
                          opacity: spotlightAnim,
                          position: 'absolute',
                          left: fabRect.x - 11,
                          top: fabRect.y - 5 + 127,
                          width: fabRect.width + 24,
                          height: fabRect.height + 24,
                          borderRadius: 10,
                          zIndex: 1000,
                          shadowColor: theme.colors.primary,
                          shadowOffset: { width: 0, height: 0 },
                          shadowOpacity: 0.8,
                          shadowRadius: 10,
                          elevation: 10,
                      },
                  ]}
              />
          );
      }

      
      const targetElement = document.getElementById(step.target);
      if (!targetElement) return null;

      const rect = targetElement.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
        return (
            <Animated.View
                style={[
                    styles.spotlight,
                    {
                      opacity: spotlightAnim,
                      position: 'absolute',
                      left: rect.x - 5,
                      top: rect.y - 5 + scrollY,
                      width: rect.width + 10,
                      height: rect.height + 10,
                      borderRadius: 8,
                      shadowColor: theme.colors.primary,
                      shadowOffset: { width: 0, height: 0 },
                      shadowOpacity: 0.8,
                      shadowRadius: 10, 
                      elevation: 10,
                    },
                ]}
            />
        );
    };

    return (
        <View style={styles.container}>
            {children}
            {renderOverlay()}
            {renderMask()}
            {renderTooltip()}
        </View>
    );
  };
  
  const styles = StyleSheet.create({
    arrow: {
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeftWidth: 6,
        borderRightWidth: 6,
        borderTopWidth: 6, 
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: 'white', 
        alignSelf: 'center',
        zIndex: 1001, 
    },
    container: {
      flex: 1,
    },

    tooltipContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: 8,
        width: window.innerWidth <= 768 ? 240 : 300,
        padding: window.innerWidth <= 768? 12 : 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        zIndex: 1005,
    },
    overlayContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
    },
    overlay: {
      position: 'absolute',
      left: 0,
      right: 0,
      backgroundColor: '#000',
    },
    spotlight: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.primary,
        borderWidth: 2,
        zIndex: 1000,
        shadowColor: theme.colors.primary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    title: {
      fontSize: window.innerWidth <= 768 ? 16 : 18,
      fontWeight: 'bold',
      marginBottom: 10,
      color: theme.colors.primary,
    },
    content: {
      fontSize: window.innerWidth <= 768 ? 14 : 16,
      marginBottom: 20,
      color: theme.colors.text,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    button: {
      padding: window.innerWidth <= 768 ? 8 : 10,
      borderRadius: 5,
      backgroundColor: '#f0f0f0',
    },
    nextButton: {
      backgroundColor: theme.colors.primary,
    },
    buttonText: {
      fontSize: window.innerWidth <= 768 ? 14 : 16,
      color: '#333',
    },
    nextButtonText: {
      color: 'white',
    },
  });

export default TourGuide;
