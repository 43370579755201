import React from 'react';
import { Caption, IconButton, Tooltip } from 'react-native-paper';
import { SectionList, StyleSheet, View } from 'react-native';
import {
  assessmentTypesState,
  SubcategorySections,
  TaskType,
} from '../../store';
import { TaskListItem } from './TaskListItem';
import { Components } from '../../server';
import { RefreshUserData } from '../RefreshUserData';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { useRecoilValue } from 'recoil';
import { colors, theme } from '../../theme';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';

import { showToast } from '../../lib/toast';
import { isWeb } from '../../lib/is-web';

export interface TaskPickerProps {
  data: SubcategorySections;
  assessments?: { [id: number]: Components.Schemas.Assessment[] };
  selected: number[];
  onSelect: React.Dispatch<React.SetStateAction<number[]>>;
  onPress?: (id: number) => void;
  childId?: number;
  classId?: number;
  isHistoryButton?: boolean;
}

export const TaskPicker = React.memo(function TaskPicker(
  props: TaskPickerProps,
) {

  const { data, assessments, selected, onSelect, onPress, childId, classId, isHistoryButton } = props;
  const { handleScroll } = useMultiFABScroll();
  const assessmentTypes = useRecoilValue(assessmentTypesState);

  const onPressCheck = React.useCallback(
    (id: number) => {
      onSelect((selected) => {
        return selected.includes(id)
          ? selected.filter((x) => x !== id)
          : [...selected, id];
      });
    },
    [onSelect],
  );

  const onSelectAll = React.useCallback(() => {
    if (
      selected.length === data.reduce((acc, curr) => acc + curr.data.length, 0)
    ) {
      onSelect([]);
      showToast('Vybrané úlohy byly odebrány', '', 'success', 3000);
    } else {
      const allTaskIds = data.flatMap((subcat) =>
        subcat.data.map((task) => task.id),
      );

      onSelect(allTaskIds);
      showToast(
        'Vybrány všechny úlohy',
        'Můžete je odebrat druhým kliknutím',
        'success',
        3000,
      );
    }
  }, [data, selected, onSelect]);

  const onPressPlain = React.useCallback(
    (id: number) => {
      if ( onPress) {
        onPress(id);
      } else {
        onPressCheck(id);
      }
    },
    [onPress, onPressCheck],
  );

  const renderItem = ({ item, index }: { item: TaskType; index: number }) => (
    <FadeInFromLeftOrRight delay={150} direction={index % 2 ? 'left' : 'right'}>
      <TaskListItem
        item={item}
        assessmentType={assessmentTypes[item.assessment_type || 0]!}
        assessments={assessments ? assessments[item.id] || [] : undefined}
        checked={selected.includes(item.id)}
        onCheck={onPressCheck}
        onPress={onPressPlain}
        childId={childId}
        classId={classId}
        isHistoryButton={isHistoryButton}
      />
    </FadeInFromLeftOrRight>
  );

  return (
    <>
      <SectionList
        {...props}
        sections={data}
        contentContainerStyle={{ padding: isWeb ? 2 : 6 }}
        refreshControl={<RefreshUserData />}
        onScroll={handleScroll}
        scrollEventThrottle={1}
        stickySectionHeadersEnabled={false}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        renderSectionHeader={({ section }) => {
          if (section.data.length === 0) {
            return null;
          }
          return (
            <View style={styles.container}>
              <View>
                <Caption style={styles.section}>{section.label}</Caption>
              </View>

              <Tooltip
                title={!!selected.length ? 'Odebrat všechny úkoly' : 'Vybrat všechny úkoly'}
                leaveTouchDelay={100}
                enterTouchDelay={100}
                theme={{ colors: { tooltip: colors.text } }}
              >
                <IconButton
                  nativeID='select_all'
                  size={30}
                  style={{ marginLeft: 'auto' }}
                  iconColor={theme.colors.primary}
                  icon={
                    selected.length === section.data.length
                      ? 'select-off'
                      : 'select-all'
                  }
                  onPress={onSelectAll}
                />
              </Tooltip>
            </View>
          );
        }}
      />
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: isWeb ? 10 : 0,
    justifyContent: 'space-between',
  },
  section: {
    marginLeft: 5,
    marginTop: 15,

    top: 0,
    left: -4,
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.colors.primary,

    padding: 3,
    paddingHorizontal: 5,
  },
});
