import React from 'react';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { SvgProps } from 'react-native-svg';
import useIsMobile from '../../hooks/use-is-mobile';
import { Tooltip } from 'react-native-paper';
import { colors, theme } from '../../theme';

interface Props {
  icons: {
    [k: string]: (p: SvgProps) => JSX.Element;
  };
  onCategoryPress: (index: number) => void;
  showLabels?: boolean;
}

const CategoryIconsChart = ({ icons, onCategoryPress, showLabels }: Props) => {
  const isMobile = useIsMobile();

  return (
    <View
     id='category_icons_chart'
     style={[styles.container, showLabels && styles.containerWithLabels]}>
      {Object.entries(icons).map(([label, Icon], i) => (
        <BubbleScaleUpAnimation key={i} delay={1100}>
          <Tooltip
            title={label}
            leaveTouchDelay={150}
            enterTouchDelay={150}
            theme={{ colors: { tooltip: colors.text } }}
          >
            <TouchableOpacity
              onPress={() => onCategoryPress(i)}
              style={showLabels && styles.iconLabelContainer}
            >
              <Icon
                style={{
                  width: showLabels ? 29 : 34,
                  height: showLabels ? 29 : 34,
                  marginBottom: showLabels && !isMobile ? 3 : 5,
                  marginRight: 8,
                }}
              />

              {showLabels && !isMobile && (
                <Text
                  style={styles.label}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {label}
                </Text>
              )}
            </TouchableOpacity>
          </Tooltip>
        </BubbleScaleUpAnimation>
      ))}
    </View>
  );
};

export default React.memo(CategoryIconsChart);

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    alignItems: 'center',
    width: 30,
  },
  containerWithLabels: {
    width: 'auto',
    maxWidth: 150,
    alignItems: 'flex-start',
  },
  iconLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  label: {
    marginBottom: 10,
    marginHorizontal: 4,
    fontSize: 10,
    flex: 1,
    maxWidth: 100,
  },
  tooltip: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 8,
  },
});
