import { Caption, Card, IconButton } from 'react-native-paper';
import FadeInFromLeftOrRight from './animations/FadeInFromLeftOrRightAnimation';
import { colors, theme } from '../theme';
import { View, StyleSheet, Text } from 'react-native';
import { format } from 'date-fns';
import { Components } from '../server';

interface Props {
  item: Components.Schemas.ClassroomNote | Components.Schemas.ChildNote;
  index: number;
  navigate: () => void;
  user: Components.Schemas.User | undefined;
}

const Notes = ({ item, index, navigate, user }: Props) => {
  return (
    <FadeInFromLeftOrRight
      direction={index % 2 ? 'left' : 'right'}
      delay={(index + 1) * 100}
    >
      <Card elevation={2} style={styles.card} onPress={navigate} id='note_card'>
        <View style={styles.viewContainer}>
          <Caption>{format(new Date(item.created_at!), 'd. M. yyyy')}</Caption>
          <Caption>
            {user?.first_name
              ? `${user?.first_name} ${user?.last_name}`
              : user?.email}
          </Caption>
        </View>
        <View style={styles.viewContainer}>
          <Text style={styles.note}>{item.note}</Text>
          <IconButton
            rippleColor={theme.colors.primary}
            iconColor={theme.colors.primary}
            icon="chevron-right"
            size={24}
          />
        </View>
      </Card>
    </FadeInFromLeftOrRight>
  );
};

export default Notes;

const styles = StyleSheet.create({
  card: {
    marginVertical: 3,
    padding: 10,
    borderRadius: 10,
    borderColor: colors.darkRed,
    borderWidth: 1,
  },
  viewContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  note: {
    maxWidth: '86%',
  },
});
