import React from 'react';
import IconWrapper from '../ui/IconWrapper';
import { colors } from '../../theme';
import { Text, StyleSheet, Dimensions, View } from 'react-native';
import { IconButton } from 'react-native-paper';
import { ChildType } from '../../store';
import { isWeb } from '../../lib/is-web';

interface Props {
  isGender: boolean;
  child: ChildType | undefined;
  isChevronRight?: boolean;
  fontSize?: number;
  delay?: number;
}

const ChildTypeGender = ({
  isGender,
  child,
  isChevronRight,
  fontSize,
  delay = 450,
}: Props) => {
  return (
    <View
      style={styles.row}
      id='child_touchable'>
      <IconWrapper
        delay={delay}
        icon={isGender ? 'human-child' : 'human-female'}
        color={isGender ? colors.blue : colors.darkRed}
        width={40}
        style={{ marginRight: 0 }}
        bgColor={isGender ? colors.veryLightBlue : colors.veryLightDarkRed}
        height={40}
      >
        <Text
          style={[
            styles.child,
            {
              color: isGender ? colors.blue : colors.darkRed,
              fontSize: fontSize ? fontSize : 15,
            },
          ]}
        >
          {child?.first_name} {child?.last_name}
        </Text>
      </IconWrapper>

      {isChevronRight && (
        <IconButton
          rippleColor={isGender ? colors.blue : colors.darkRed}
          iconColor={isGender ? colors.blue : colors.darkRed}
          icon="chevron-right"
          size={20}
        />
      )}
    </View>
  );
};

export default ChildTypeGender;

const deviceWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  child: {
    fontWeight: '500',
    textAlign: 'left',

    minWidth: isWeb ? 'auto' : deviceWidth / 4.2,
    maxWidth: isWeb ? 'auto' : deviceWidth / 1.6,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
