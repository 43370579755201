import React from 'react';
import {StyleSheet, Dimensions } from 'react-native';
import { Portal, Dialog, Paragraph, Button, Title } from 'react-native-paper';
import { useTourControl } from './TourGuideControlProvider';
import { theme } from '../../theme';
import { useAuth } from '../../hooks/use-auth';
import { isWeb } from '../../lib/is-web';

const { width } = Dimensions.get('window');
const dialogWidth = Math.min(width - 48, 400);

export const TourWelcomeDialog = () => {
  const { hasSeenWelcome, acknowledgeWelcome, isInitialized, setEnableTour } = useTourControl();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { authenticated, initializing } = useAuth(); 

  // if (!isWeb) {
  //   return null;
  // }

  React.useEffect(() => {
    if (!initializing && authenticated && isInitialized && !hasSeenWelcome) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isInitialized, hasSeenWelcome]);


  const handleEnableTour = async () => {
    try {
      setIsProcessing(true);
      await setEnableTour(true)
      await acknowledgeWelcome();
      setIsVisible(false);
    } catch (error) {
      console.error('Error enabling tour:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  const handleDismiss = async () => {
    await acknowledgeWelcome();
    setIsVisible(false);
  };
  

  if ( !isInitialized || hasSeenWelcome) {
    return null;
  }
  
  return (
    <Portal>
      <Dialog 
        visible={isVisible}
        onDismiss={handleDismiss}
        style={styles.dialog}
      >
        <Dialog.Title style={styles.title}>
          Nová funkce: Průvodce aplikací
        </Dialog.Title>
        <Dialog.Content>
          <Paragraph style={styles.paragraph}>
            Představujeme nového průvodce aplikací, který vám pomůže lépe se seznámit s aplikací a s danými funkcemi.
          </Paragraph>
          <Paragraph style={styles.paragraph}>
            Chcete zapnout průvodce nyní? Můžete jej kdykoliv zapnout nebo
            vypnout v sekci Profilu.
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions style={styles.actions}>
          <Button 
            onPress={handleDismiss}
            style={styles.button}
            mode="outlined"
          >
            Teď ne
          </Button>
          <Button 
            onPress={handleEnableTour}
            style={styles.button}
            mode="contained"
            buttonColor={theme.colors.primary}
          >
            Zapnout průvodce
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: {
    width: dialogWidth,
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    marginVertical: 'auto',
    maxHeight: '90%',
  },
  title: {
    fontSize: 20,
    color: theme.colors.primary,
    textAlign: 'center',
    marginBottom: 8,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 12,
    textAlign: 'center',
    color: theme.colors.text,
  },
  actions: {
    justifyContent: 'center',
    padding: 16,
    gap: 8,
  },
  button: {
    minWidth: 120,
  },
});