import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {  StyleSheet, View} from 'react-native';
import { colors } from '../../theme';
import { IconButton } from 'react-native-paper';
import { CustomTooltip } from '../ui/Tooltip';
type Props = {
    onPress: () => void;
    onPressStart: () => void;
    borderColor?: string;
  };

export const NoteButton = React.memo(({ onPress, onPressStart, borderColor }: Props) => {
    return (
        <CustomTooltip
          style={styles.container}  
          title="Zobrazit poznámky"
          position="left"
          backgroundColor={borderColor}                                                   
          >                                                                                                                                  
              <IconButton
                style={styles.noteButton}  
                size={21}
                onPressIn={() => onPressStart()}
                onPress={onPress}
                icon={() => (
                    <Icon
                      name={'note-multiple-outline'}
                      size={16}
                      color={borderColor}
                    />
                  )}
              >
              </IconButton>
          </CustomTooltip>
    );
  });

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: -14,
      top: -6,
    },

    noteButton: {
      backgroundColor: colors.white,
      borderRadius: 20,
      zIndex: 20,
      elevation: 3, 
      shadowColor: '#000', 
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 0.84,

    },
  });