import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { atom, useRecoilState } from 'recoil';
import { useApi } from '../../hooks/use-api';
import { isWeb } from '../../lib/is-web';

interface UserPreferences {
  has_seen_tour_welcome: boolean;
  tour_enabled: boolean;
}

export const userPreferencesState = atom<UserPreferences>({
  key: 'userPreferencesState',
  default: {
    has_seen_tour_welcome: false,
    tour_enabled: false
  }
});

interface TourControlContextType {
  isTourEnabled: boolean;
  hasSeenWelcome: boolean;
  isInitialized: boolean;
  initializeTourSetting: () => Promise<void>;
  toggleTourEnabled: () => Promise<void>;
  setEnableTour: (enable: boolean) => Promise<void>;
  resetAllTours: () => Promise<void>;
  acknowledgeWelcome: () => Promise<void>;
}

export const TourControlContext = React.createContext<TourControlContextType>({
  isTourEnabled: false,
  hasSeenWelcome: false,
  isInitialized: false,
  initializeTourSetting: async () => {},
  toggleTourEnabled: async () => {},
  setEnableTour: async () => {},
  resetAllTours: async () => {},
  acknowledgeWelcome: async () => {},
});

const STORAGE_KEY = 'tour_preferences';

export const TourControlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { authAxios } = useApi();
  const [preferences, setPreferences] = useRecoilState(userPreferencesState);
  const [isInitialized, setIsInitialized] = useState(false);

  if (!isWeb) {
    return <>{children}</>;
  }

  useEffect(() => {
    if (isInitialized ) {
      AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(preferences));
    }
  }, [preferences, isInitialized]);

  useEffect(() => {
    initializeTourSetting();
  }, []);

  const initializeTourSetting = async () => {
    try {
      const { data } = await authAxios.get('/user-preferences/status/');
      const backendPrefs = {
        has_seen_tour_welcome: data.has_seen_tour_welcome ?? false,
        tour_enabled: data.tour_enabled ?? false
      };

      await AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(backendPrefs));
      setPreferences(backendPrefs);

    } catch (error) {
      console.error('Error initializing from backend:', error);
      
      try {
        const localPrefs = await AsyncStorage.getItem(STORAGE_KEY);
        if (localPrefs) {
          setPreferences(JSON.parse(localPrefs));
        }
      } catch (localError) {
        console.error('Error reading local storage:', localError);
      }
    } finally {
      setIsInitialized(true);
    }
  };

  const toggleTourEnabled = async () => {
    try {
      const { data } = await authAxios.post('/user-preferences/toggle_tour/');

      setPreferences(prev => ({
        ...prev,
        tour_enabled: data.tour_enabled
      }));

      if (data.tour_enabled) {
        const keys = await AsyncStorage.getAllKeys();
        const tourKeys = keys.filter(key => key.startsWith('tour_completed_'));
        await AsyncStorage.multiRemove(tourKeys);
      }

    } catch (error) {
      console.error('Error toggling tour state:', error);
    }
  };

  const setEnableTour = async (enable: boolean) => {
    try {
      const { data } = await authAxios.post('/user-preferences/toggle_tour/', {
        force_enable: enable
      });
      
      await resetAllTours();
    
    } catch (error) {
      console.error('Error toggling tour state:', error);
    }
  };

  const resetAllTours = async () => {
    try {
      const keys = await AsyncStorage.getAllKeys();
      const tourKeys = keys.filter(key => key.startsWith('tour_completed_'));
      await AsyncStorage.multiRemove(tourKeys);

      const { data } = await authAxios.post('/user-preferences/toggle_tour/', {
        force_enable: true
      });
      
      setPreferences(prev => ({
        ...prev,
        tour_enabled: true
      }));
    } catch (error) {
      console.error('Error resetting all tours:', error);
    }
  };

  const acknowledgeWelcome = async () => {
    try {
      await authAxios.post('/user-preferences/acknowledge_tour_welcome/');
      setPreferences(prev => ({
        ...prev,
        has_seen_tour_welcome: true
      }));
    } catch (error) {
      console.error('Error acknowledging welcome:', error);
    }
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <TourControlContext.Provider
      value={{
        isTourEnabled: preferences.tour_enabled,
        hasSeenWelcome: preferences.has_seen_tour_welcome,
        isInitialized,
        initializeTourSetting,
        toggleTourEnabled,
        setEnableTour,
        resetAllTours,
        acknowledgeWelcome,
      }}
    >
      {children}
    </TourControlContext.Provider>
  );
};

export const useTourControl = () => {
  const context = React.useContext(TourControlContext);
  if (!context) {
    throw new Error('useTourControl must be used within a TourControlProvider');
  }
  return context;
};