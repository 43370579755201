import React from 'react';
import { AssessmentTypePicker } from '../assessments/AssessmentTypePicker';
import { Card, IconButton, Subheading, TextInput, Tooltip } from 'react-native-paper';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
import { DifficultyIcon } from '../ui/DifficultyIcon';
import { colors, theme } from '../../theme';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { AssessmentTypeType, TaskType } from '../../store';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';

interface Props {
  assessment: {
    task: number;
    option: number;
    note: string;
  };
  setAssessments: (
    value: React.SetStateAction<{
      [key: number]: {
        task: number;
        option: number;
        note: string;
      };
    }>,
  ) => void;
  assessmentType: AssessmentTypeType | undefined;
  onRemoveTasks: (task: number) => void;
  activeNote: number | undefined;
  onActiveNote: (id: number) => void;
  task: TaskType | undefined;
}

export const AssessmentsRenderContent = React.memo(
  function AssessmentsRenderContent({
    assessment,
    setAssessments,
    assessmentType,
    onRemoveTasks,
    activeNote,
    onActiveNote,
    task,
  }: Props) {
    const [id, ...rest] = (task?.codename || '').split(' ');

    // hack for setting default value for some assessments
    // React.useEffect(() => {
    //   const ids = [149, 150, 151, 152];
    //   if (ids.includes(assessment.task)) {
    //     setAssessments((xs) => ({
    //       ...xs,
    //       [assessment.task]: {
    //         ...xs[assessment.task]!,
    //         option: 25,
    //       },
    //     }));
    //   }
    // }, [assessment.task, setAssessments]);

    return (
      <FadeInFromLeftOrRight delay={50}>
        <Card
          style={{
            padding: 6,
            backgroundColor: colors.veryLightOrange,
            marginTop: 4,
            borderColor: colors.lightOrange,
            borderWidth: 1,
            marginBottom: 28,
            paddingBottom: 30,
            position: 'relative',
          }}
          elevation={2}
        >
          <View
            style={[
              {
                alignItems: 'flex-end',
                flexDirection: 'row',
                padding: 4,

                justifyContent: 'space-between',
              },
            ]}
          >
            {task?.codename ? (
              <Text style={styles.number}>
                {(id ? `${id}. ` : '') + rest.join(' ')}
              </Text>
            ) : null}
            <DifficultyIcon difficulty={task?.difficulty} />
            <Icon.Button
              name="close"
              borderRadius={100}
              onPress={() => onRemoveTasks(assessment.task)}
              backgroundColor={colors.red}
              style={{ padding: 5, alignSelf: 'flex-end' }}
              iconStyle={{ marginRight: 0 }}
            />
          </View>

          <View
            style={[styles.header, { paddingHorizontal: 3, marginBottom: 0 }]}
          >
            <Subheading style={styles.subheading}>
              {task?.task_description}
            </Subheading>
            {task?.ageString ? (
              <Text style={styles.age}>
                Věk:{'\xa0'}
                {task.ageString}
              </Text>
            ) : null}
          </View>
          <>
            <AssessmentTypePicker
              options={assessmentType?.options}
              selected={assessment.option}
              onSelect={(option) =>
                setAssessments((xs) => ({
                  ...xs,
                  [assessment.task]: {
                    ...xs[assessment.task]!,
                    option,
                  },
                }))
              }
            />
          </>
              <IconButton
                rippleColor={colors.lightOrange}
                onPress={() => {
                  onActiveNote(assessment.task);
                }}
                style={{
                  alignSelf: 'flex-end',
                  backgroundColor: colors.white,
                  width: 40,
                  height: 40,
                  bottom: -50,
                  borderRadius: 100,
                  position: 'absolute',
                  elevation: 2,
                }}
                iconColor={theme.colors.primary}
                icon={activeNote ? 'pencil-minus' : 'pencil-plus'}
              />

          <View style={{ height: 7 }} />
          {activeNote && (
            <FadeInFromTopAnimation>
              <TextInput
                value={assessment.note}
                onChangeText={(note) =>
                  setAssessments((xs) => ({
                    ...xs,
                    [assessment.task]: {
                      ...xs[assessment.task]!,
                      note,
                    },
                  }))
                }
                label="Poznámka"
                autoComplete="off"
                numberOfLines={1}
                style={styles.whiteBg}
                right={
                  assessment.note ? (
                    <TextInput.Icon
                      icon="close-circle"
                      onPress={() =>
                        setAssessments((xs) => ({
                          ...xs,
                          [assessment.task]: {
                            ...xs[assessment.task]!,
                            note: '',
                          },
                        }))
                      }
                      color={theme.colors.primary}
                    />
                  ) : null
                }
              />
            </FadeInFromTopAnimation>
          )}
        </Card>
      </FadeInFromLeftOrRight>
    );
  },
);

const styles = StyleSheet.create({
  subheading: {
    color: colors.text,
    paddingLeft: 3,
    fontSize: 15,
    maxWidth:
      Dimensions.get('window').width > 550
        ? Dimensions.get('window').width
        : Dimensions.get('window').width - 110,
  },
  whiteBg: {
    backgroundColor: 'white',
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 7,
    marginRight: 5,
  },
  number: {
    color: colors.grey,
    padding: 2,
  },
  age: {
    color: colors.grey,
    fontSize: 14,
    fontWeight: '400',
    position: 'absolute',
    bottom: 44,
    right: 40,
  },
});
